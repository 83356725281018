<!-- Footer -->
<footer>
    <pre>
           (  (    ( (  (        .  (     (  (
  ()) )\  )\))\ )\  )\ )\ . )\   ()) )\  )\ )\
 ((_))(_)((_)(_)(_)((_)(_) ((_) ((_))_()((_)(_)
(/ __| _ \ \ / / _ \_   _|/ _ \(/ __|   \_   _|
| (__|   /\   /|  _/ | | | (_) | (__| - | | |
 \___|_|_\ |_| |_|   |_|  \___/ \___|_|_| |_|
    </pre>
    <hr><!-- comment -->
    <p class="from">
        ... from catalonia with ꒒ ০ ⌵ ୧ ♡
    </p>
</footer>