<!-- Toolbar -->
<div class="toolbar" role="banner">
    <svg class="logo" version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="42px" height="50px" viewBox="0 0 438.000000 521.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,521.000000) scale(0.100000,-0.100000)"
           fill="#FFFFFF" stroke="none">
            <path d="M1368 5038 c-91 -90 -190 -194 -220 -233 -149 -190 -364 -575 -442
                  -793 -59 -164 -125 -400 -137 -492 -18 -127 -18 -838 -1 -868 17 -30 33 -24
                  47 18 11 32 35 54 35 31 0 -6 23 -32 50 -58 28 -26 50 -52 50 -58 0 -6 -41
                  -51 -91 -100 -99 -98 -187 -209 -248 -314 -81 -139 -141 -270 -165 -362 -13
                  -52 -34 -122 -46 -154 -34 -95 -36 -122 -9 -151 31 -33 69 -31 140 7 33 18 62
                  29 66 26 3 -4 -5 -38 -17 -77 -18 -55 -29 -74 -50 -85 -37 -19 -68 -76 -114
                  -210 -47 -139 -141 -359 -172 -405 -46 -67 -22 -119 58 -126 24 -2 118 -11
                  209 -19 166 -15 359 -59 440 -100 32 -17 33 -22 13 -127 -4 -22 2 -35 33 -65
                  21 -21 52 -56 68 -77 63 -83 337 -181 565 -202 41 -3 80 -11 87 -16 7 -5 63
                  -9 125 -7 63 1 141 -4 175 -11 45 -9 104 -11 205 -6 79 4 178 9 221 10 43 1
                  82 7 85 12 4 6 52 20 107 33 89 20 104 26 135 60 28 30 51 42 115 60 44 13
                  124 39 178 58 55 19 122 41 150 50 29 8 59 21 67 27 8 7 71 42 139 77 129 67
                  181 111 181 154 0 14 -9 30 -20 37 -27 17 -25 37 3 42 111 23 375 93 440 117
                  89 32 117 56 117 100 0 21 -9 35 -37 56 -67 50 -128 120 -150 175 -19 46 -69
                  164 -106 249 -21 49 -37 23 -37 -60 0 -136 38 -262 106 -358 19 -26 30 -51 25
                  -56 -12 -12 -378 -102 -496 -121 -75 -13 -104 -22 -117 -38 -40 -47 -12 -90
                  63 -98 46 -4 54 -8 54 -25 0 -25 -129 -96 -290 -159 -142 -56 -422 -145 -494
                  -157 -47 -7 -62 -15 -94 -49 -37 -41 -38 -41 -105 -40 -37 1 -173 -1 -302 -5
                  -263 -9 -511 5 -656 34 -49 10 -137 37 -196 60 -149 58 -199 101 -120 101 96
                  0 120 105 35 149 -21 10 -78 44 -126 75 -49 30 -113 64 -143 75 -109 41 -281
                  79 -416 91 -113 11 -138 16 -138 29 0 8 20 64 45 123 25 59 70 178 100 264 30
                  86 67 174 82 195 96 132 128 204 140 315 5 47 3 53 -20 69 -31 20 -84 9 -141
                  -30 -64 -43 -67 -36 -36 81 11 41 46 126 76 187 91 183 173 296 320 443 143
                  142 166 180 144 234 -8 19 -26 34 -61 48 -27 12 -49 27 -49 35 0 8 17 21 38
                  30 20 9 88 37 149 62 62 26 116 51 119 56 3 5 55 34 114 64 60 30 114 64 120
                  74 15 30 12 66 -9 92 -38 47 -201 23 -201 -30 0 -33 -31 -59 -102 -85 -41 -14
                  -141 -62 -222 -106 -82 -44 -152 -77 -157 -74 -5 3 -7 15 -4 27 14 62 35 303
                  35 405 1 201 15 303 65 476 25 88 63 205 84 260 89 235 296 599 437 766 70 84
                  209 222 297 295 51 43 57 51 57 86 0 41 -26 69 -66 69 -14 0 -76 -54 -186
                  -162z"/>
            <path d="M1638 4858 c-8 -15 -14 -78 -16 -168 -4 -131 0 -166 32 -356 39 -232
                  75 -350 158 -524 57 -121 98 -225 98 -250 0 -10 -10 -20 -24 -24 -13 -3 -40
                  -24 -61 -47 -39 -44 -39 -43 -14 -131 17 -60 5 -77 -37 -55 -47 24 -200 48
                  -339 54 -112 5 -142 3 -214 -16 -182 -46 -268 -133 -185 -188 24 -16 28 -15
                  97 21 98 49 198 70 307 61 136 -11 247 -33 299 -60 l48 -26 56 21 c35 13 68
                  35 91 60 41 46 41 44 21 100 -24 69 -22 74 34 67 94 -11 248 -71 469 -184 221
                  -112 301 -143 375 -143 31 0 37 3 35 18 -2 10 3 17 12 17 9 0 14 -8 13 -20 -4
                  -27 55 -67 136 -94 36 -12 70 -21 78 -21 28 0 25 -30 -7 -66 -18 -21 -29 -43
                  -26 -51 3 -8 6 -19 6 -24 0 -14 50 -35 115 -48 64 -14 195 -69 195 -82 0 -5
                  -19 -18 -42 -30 -24 -12 -53 -31 -65 -42 -37 -33 -29 -78 17 -102 22 -11 35
                  -34 60 -99 18 -46 50 -112 73 -146 37 -57 39 -66 29 -92 -14 -38 -1 -75 31
                  -87 14 -5 83 -11 155 -13 116 -2 133 -1 151 16 41 37 15 126 -37 126 -40 0
                  -138 33 -169 56 -19 14 -45 43 -59 65 -34 55 -85 190 -79 210 7 20 67 31 101
                  18 67 -25 124 0 124 53 0 44 -10 57 -71 89 -32 18 -59 38 -59 45 0 7 47 54
                  105 105 140 124 244 252 364 449 37 60 71 150 71 187 0 39 -26 26 -82 -40 -87
                  -104 -170 -207 -227 -282 -29 -38 -72 -90 -95 -114 -61 -64 -255 -231 -269
                  -231 -21 0 -137 53 -137 62 0 6 9 21 20 35 13 17 20 41 20 69 0 64 -17 72
                  -175 73 -134 1 -195 8 -195 21 0 4 24 10 53 14 71 9 250 59 341 96 183 72 648
                  321 790 422 44 31 66 36 66 12 0 -87 -89 -650 -150 -956 -52 -254 -206 -712
                  -293 -866 -33 -60 -31 -96 8 -114 59 -27 92 12 174 208 107 255 203 583 259
                  884 60 317 69 371 106 657 19 144 43 284 52 311 16 43 16 54 5 75 -16 31 -56
                  50 -84 41 -12 -4 -51 -30 -87 -59 -135 -108 -387 -256 -683 -401 -161 -79
                  -231 -108 -268 -112 -27 -2 -49 -8 -49 -13 0 -16 -59 -39 -197 -76 -190 -51
                  -217 -50 -323 16 -131 80 -385 204 -485 235 -51 16 -99 38 -111 51 -11 12 -36
                  64 -54 116 -19 52 -60 148 -91 214 -79 164 -114 275 -144 451 -51 294 -112
                  574 -126 574 -4 0 -14 -10 -21 -22z"/>
            <path d="M3540 1878 c0 -41 103 -381 157 -518 31 -78 48 -100 79 -100 57 0 58
                  86 3 223 -77 193 -193 404 -226 415 -8 2 -13 -6 -13 -20z"/>
            <path d="M953 1831 c-114 -90 -154 -321 -83 -478 16 -34 40 -69 55 -79 24 -16
                  29 -16 58 -2 56 27 135 96 162 143 40 67 58 148 53 233 -4 63 -10 79 -41 125
                  -37 54 -98 97 -135 97 -11 0 -42 -17 -69 -39z"/>
            <path d="M2601 1659 c-32 -6 -52 -18 -73 -43 -15 -19 -44 -44 -64 -57 -38 -23
                  -65 -72 -90 -164 -32 -116 -4 -207 87 -288 68 -60 104 -75 175 -76 123 -2 260
                  86 315 204 32 67 36 116 15 187 -38 122 -135 209 -264 237 -29 6 -54 11 -55
                  10 -1 -1 -22 -5 -46 -10z"/>
            <path d="M1693 909 c-37 -11 -42 -25 -13 -34 11 -4 18 -11 15 -16 -8 -12 25
                  -25 34 -12 3 5 25 15 49 22 32 9 42 17 40 29 -4 20 -74 26 -125 11z"/>
        </g>
    </svg>
    <div class="spacer"></div>

</div>